.customer-login {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: url(../../../../assets/images/kioskBGI.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1px none;
  height: 100vh;
  width: 100%;
}

.user-name {
  color: #fff;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 3.2vw;
  top: 1.8vw;
  margin: 0;
}

.welcome-text {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter, sans-serif;
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.welcome-text-des {
  color: #929098;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  padding-top: 1vh;
}

.cl-mobile-icon {
  width: 18.5vw;
  min-height: 23vh;
  border-radius: 25px;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
}

.cl-img {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
}

.cl-or-txt {
  width: 23vw;
  min-height: 23vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
  padding-bottom: 5vh;
  font-size: 2.1vw;
  opacity: 0.7;
  word-wrap: break-word;
}

.cl-email-icon {
  width: 18.5vw;
  min-height: 23vh;
  border-radius: 25px;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-family: Inter;
}

.left-bottom-images {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}

.bottom-img {
  padding: 1vw 1vw 3vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.spin-container {
  position: relative;
}

.custom-box {
  width: 92%;
  height: 11.8vh;
  background-color: #eec302;
  text-align: center;
  margin-top: 1.5vh;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-box:hover {
  /* background-color: #EEC302 !important;  */
  color: #fff !important;
  cursor: pointer !important;
}

/***********Mobile Component************/
.mc-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 93vh;
}

.mc-left-div {
  position: relative;
  bottom: 2%;
  margin-top: -5%;
  /* left: 4%; */
  bottom: 3rem;
  display: flex;
  justify-content: center;
}

.mc-input-field {
  position: relative;
  width: 26rem;
  border: 1px solid #fff;
  border-radius: 7px;
  color: #fff;
  font-size: 1.6rem;
  height: 3.4rem;
  padding: 1.2rem;
  background: transparent !important;
  outline: none;
}

.login-input-field {
  position: relative;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 7px;
  color: #fff;
  font-size: 1.6rem;
  height: 9vh;
  padding: 2vh;
  background: transparent !important;
}

.mc-input-lable {
  position: absolute;
  top: calc(50% - 1.5rem);
  font-size: 1.3rem;
  color: #a0a8b2;
  left: 10%;
  background-color: white;
  padding: 0 3%;
}

.mc-continue-btn {
  position: relative;
  background-color: #eec302;
  border-color: #eec302;
  width: 26.2rem;
  height: 3.7rem;
  border-radius: 7px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
}

.mc-continue-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}

.mc-mobile-icon {
  height: 4vh;
  width: 3vw;
  position: relative;
  right: 51%;
}

.mc-kb-col {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 2%;
}

.mc-kb-div {
  height: 27.3rem;
  width: 23.6rem;
  position: relative;
  top: 19vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.mc-cl-img {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  bottom: -4vh;
}

/***********Email Component************/
.email-icon-bg {
  height: 12.6vh;
  width: 9.3vw;
  position: relative;
  left: 50%; /* Adjusted to center horizontally */
  transform: translateX(-50%); /* Added to center horizontally */
}

.email-icon {
  margin-bottom: 1vh;
  height: 7vw;
  width: 7vw;
  padding: 1.3vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  top: -2vh;
  /* bottom: -1vh; */
}

.email-input {
  position: relative;
  width: 25rem;
  border: 1px solid #0580bd;
  border-radius: 5px;
  color: black;
  font-size: 2rem;
  height: 5rem;
  padding: 1.2rem;
  top: 15%;
}

.email-lable {
  position: absolute;
  top: 60%;
  font-size: 1.3rem;
  color: #a0a8b2;
  left: 3%;
  background-color: white;
  padding: 0 2%;
}

.email-keyboard-div {
  height: 18rem;
  width: 100%;
  position: relative;
  top: 27%;
}

.email-kb-div {
  height: 40vh;
  padding: 1.5vh 11vw 1.5vh 11vw;
  width: 100%;
  /* top: 2vh; */
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.email-continue-btn {
  background-color: #eec302;
  border-color: #eec302;
  width: 26.2rem;
  height: 3.7rem;
  border-radius: 7px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.5%;
}

.email-continue-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}

/***********Scan Component************/

.scan-cl-img {
  height: 26vw;
  width: 26vw;
  border-radius: 50%;
  border: 1px solid #fff;
  position: relative;
  background-color: #fff;
}

/* Payment Secetion */

.proceed-to-pay-btn {
  height: 11vh;
  background-color: #eec302;
  width: 21.4vw;
  color: #fff;
  border-radius: 7px;
  font-size: 1.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.proceed-to-pay-btn:hover {
  background-color: #eec302 !important;
  color: #fff !important;
}
